<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    class="dialog-style"
  >
    <v-form v-model="valid">
      <v-card flat style="overflow: hidden">
        <v-card-title class="secondary py-1" style="height:40px;">
          <span class="dialog-title">Medical Staff</span>
          <v-spacer />
          <v-icon
            small
            color="primary"
            @click="$emit('false')"
            >mdi-window-close
          </v-icon>
        </v-card-title>
        <v-card-text color="primary" class="mt-5">
          <span>Welcome to medical staff registration form and setup process <span style="color: red">(required fields *)</span></span>
        </v-card-text>
        <v-row class="pa-6">
          <v-col cols="7">
            <v-row class="pl-4 pb-0">
              <span style="font-size: 18px;">Personal details</span>
            </v-row>
            <v-row>
              <v-col cols="12" lg="6" md="6" sm="12">
                <v-card flat color="#E8E8E8" class="dialog-img-selector d-flex align-center justify-center">
                  <v-img
                    v-if="`${profile}`"
                    :src="`${profile}`"
                    width="145"
                    height="145"
                    style="border-radius: 12px; "
                  ></v-img>
                </v-card>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12">
                <v-row dense class="pb-2">
                  <v-col>
                    <v-text-field
                      v-model="firstName"
                      label="Name *"
                      prepend-inner-icon="mdi-account mr-4"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :rules="[(rule) => !!rule || '']"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense class="pb-2">
                  <v-col>
                    <v-text-field
                      v-model="lastName"
                      label="Surname *"
                      prepend-inner-icon="mdi-account mr-4"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :rules="[(rule) => !!rule || '']"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense class="pb-2">
                  <v-col>
                    <validation-provider ref="email" :name="$t('email')" rules="required|email" v-slot="{ errors }" >
                      <v-text-field
                        v-model="email"
                        label="Email *"
                        prepend-inner-icon="mdi-email mr-4"
                        dense
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        style="border-radius: 12px !important; height: 40px"
                        @change="errors.length > 0 ? emailErrors = true : emailErrors = false"
                        :error-messages="errors"
                      ></v-text-field>
                  </validation-provider>
                  </v-col>
                </v-row>
                <v-row dense class="pb-2">
                  <v-col>
                    <v-text-field
                      ref="phoneNumber"
                      v-model="phoneNumber"
                      validate-on-blur
                      label="Phone number *"
                      prepend-inner-icon="mdi-cellphone mr-4"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :rules="phoneNumberError ? ['Phone number must be valid'] : []"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row cols="12">
              <v-col cols="12" lg="6" md="6" sm="12" class="pt-0 pb-0 mb-2">
                <v-radio-group class="pl-10 pt-0" v-model="gender" row>
                  <v-radio
                    label="Male"
                    value="1"
                  ></v-radio>
                  <v-radio
                    label="Female"
                    value="2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="pt-0">
                <BirthdatePicker :inputValue.sync="birthdate" />
              </v-col>
            </v-row>
            <v-row class="pl-4 pb-3">
              <span style="font-size: 18px;">Contact details</span>
            </v-row>
            <v-row dense>
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-text-field
                  ref="address"
                  @blur="locationDetails()"
                  v-model="addressToValidate"
                  validate-on-blur
                  label="Address, State, Zip, City, Country *"
                  prepend-inner-icon="mdi-map-marker mr-4"
                  dense
                  background-color="#E8E8E8"
                  rounded
                  outlined
                  placeholder="Address, State, Zip, City, Country"
                  style="border-radius: 12px !important; height: 40px"
                  :rules="[(rule) => !!rule || '']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <span class="d-flex align-left ml-4">{{ formattedAddress }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5">
            <v-row class="pl-4 pb-4">
              <span style="font-size: 18px;">Work details</span>
            </v-row>
            <v-row dense cols="12" class="pb-2">
              <v-col class="pb-3" cols="12" lg="12" md="6" sm="12">
                <v-select
                  v-model="role"
                  label="Title"
                  :items="roles"
                  item-text="roleNameForDisplay"
                  item-value="id"
                  prepend-inner-icon="mdi-badge-account mr-4"
                  dense
                  background-color="#E8E8E8"
                  rounded
                  outlined
                  style="border-radius: 12px !important; height: 40px"
                  :rules="[(rule) => !!rule || '']"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  v-model="hospitalId"
                  :label="role !== 4 ? 'Select Hospital' : 'Select Hospital *'"
                  :items="hospitals"
                  item-value="id"
                  item-text="hospitalName"
                  prepend-inner-icon="mdi-hospital-building mr-4"
                  outlined
                  dense
                  background-color="#E8E8E8"
                  rounded
                  style="border-radius: 12px !important; height: 40px"
                  :rules="[(rule) => !!rule || '']"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row dense cols="12" class="pb-2">
              <v-col cols="12" lg="12" md="6" sm="12">
                <v-text-field
                  label="Licence number *"
                  outlined
                  v-model="licenceNumber"
                  prepend-inner-icon="mdi-account mr-4"
                  dense
                  background-color="#E8E8E8"
                  rounded
                  style="border-radius: 12px !important"
                  :rules="[(rule) => !!rule || '']"
                ></v-text-field>
              </v-col>
              <v-col class="pt-4">
                <v-row class="pl-4 pb-4">
                  <span style="font-size: 16px;">Specializations</span>
                </v-row>

                <v-list :disabled="role === 4" dense height="160px" :class="role === 4 ? 'specializations-list-disabled' : 'specializations-list'">
                  <v-list-item-group
                    v-model="selected"
                    active-class="primary--text"
                    multiple
                  >
                    <template v-for="(item) in verifiers">
                      <v-list-item :key="item.lowValue" :value="item.lowValue">
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title class="d-flex align-left pl-2 pt-1" v-text="item.longDescription"></v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-icon v-if="active" color="primary">mdi-check</v-icon>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <div class="my-6">
            <v-btn class="table-create-button" @click="$emit('false')">
              <v-icon class="icon-cancel">mdi-window-close</v-icon>
              <span>{{ $t('cancel') }}</span>
            </v-btn>
            <v-btn class="table-create-button mx-4" :disabled="submit" @click="createStaff">
              <v-icon class="icon">mdi-plus</v-icon>
              <span>{{ $t('save') }}</span>
            </v-btn>
            <v-progress-circular indeterminate color="primary" v-if="loading" />
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
    <Alert :title="title" :status="snackStatus" :color="snackColor"></Alert>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
import Alert from "@/views/Client/components/Alert.vue";
import BirthdatePicker from "@/views/Client/components/BirthdatePicker";
import image from "@/assets/account-outline.png"
import { AsYouType } from 'libphonenumber-js';
import { phoneNumberFormatter } from '../../utils/luxon-formater'

export default {
  components: {
    Alert,
    BirthdatePicker,
  },
  props: ["dialog"],
  data: () => ({
    loading: false,
    selected: [],
    profile: image,
    title: "",
    snackStatus: false,
    snackColor: "",
    status: "",
    firstName: "",
    lastName: "",
    phone: "",
    username: "",
    email: "",
    emailErrors: false,
    userTimeZone: "",
    address_info: {
      address: "",
      postal_code: "",
      city: "",
      state: "",
      country: "",
      lat: 42.001071,
      lng: 20.957554,
    },
    gender: "",
    birthdate: "2002-01-01",
    role: -1,
    roles: [],
    hospitalId: -1,
    hospitals: [],
    valid: false,
    phoneNumber: null,
    phoneNumberError: false,
    licenceNumber: null,
    specializations: null,
    addressToValidate: null,
    formattedAddress: null,
  }),
  computed: {
    ...mapState({
      hospitalsFromRepo: (state) => state.hospitals.hospitals,
      rolesFromRepo: (state) => state.roles.roles,
      timeZones: (state) => state.verifiers.timeZones,
      verifiersFromRepo: state => state.verifiers.verifiers
    }),
    verifiers () {
      return this.verifiersFromRepo
    },
    submit () {
      if (!this.phoneNumberError) {
        return true
      }
      if (this.emailErrors) {
        return true
      }
      return false
    },
  },
  watch: {
    role (val) {
      if (val === 4) {
        this.selected = []
      }
    },
    phoneNumber (val) {
      if (val !== undefined) {
        this.phoneNumber = new AsYouType().input(val);
        this.phoneNumberError = phoneNumberFormatter(val)
      }
    },
  },
  async mounted() {
    await this.getHospitals();
    await this.getRoles();
    await this.getSpecializations()
  },
  methods: {
    async getSpecializations() {
      let header = {
        domainValue: "SPECIALIZATION_TYPE",
      };
      await this.$store
        .dispatch("verifiers/getVerifiersForDomain", header)
        .then(() => {
          this.specializationTypes = this.verifiersFromRepo;
        });
    },
    async editNurseMetaData (metadatabody) {
      await this.$store.dispatch("nursemetadata/editNurseMetaData", metadatabody)
    },
    async updateDoctorMetaData (metadatabody) {
      await this.$store.dispatch("doctormetadata/editDoctorMetaData", metadatabody)
    },
    async assignDoctorToSpetialization(specializationbody) {
      await this.$store.dispatch("doctormetadata/assignDoctorToSpetialization", specializationbody)
    },
    async timeZone(lat, lng) {
      let object = {
        lat: lat,
        lng: lng
      }
      const time_zone = await this.$store.dispatch('googleAPI/getTimeZone', object)
      return time_zone
    },
    async locationDetails () {
      if (this.addressToValidate) {
        const geo_location = await this.$store.dispatch('googleAPI/getGeoLocation', this.addressToValidate)
        const address_components = geo_location.address_components
        this.address_info.lat = geo_location.geometry.location.lat
        this.address_info.lng = geo_location.geometry.location.lng
        this.formattedAddress = geo_location.formatted_address
        this.address_info.address = this.formattedAddress
        address_components.forEach(ac => {
          if (ac.types.includes('administrative_area_level_1')) {
            this.address_info.state = ac.long_name
          }
          if (ac.types.includes('locality')) {
            this.address_info.city = ac.long_name
          }
          if (ac.types.includes('country')) {
            this.address_info.country = ac.long_name
          }
          if (ac.types.includes('postal_code')) {
            this.address_info.postal_code = ac.long_name
          }
        })
      }
    },
    async createStaff() {
      this.loading = true;
      this.snackStatus = false;
      const timezone = await this.timeZone(this.address_info.lat, this.address_info.lng)
      const body = {
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phoneNumber,
        email: this.email,
        address: this.address_info.address,
        zip: this.address_info.zip,
        city: this.address_info.city,
        state: this.address_info.state,
        country: this.address_info.country,
        addressLatitude: this.address_info.lat ? this.address_info.lat : 0,
        addressLongitude: this.address_info.lng ? this.address_info.lng : 0,
        username: this.email,
        password: '123456789',
        gender: this.gender,
        birthdate: this.birthdate,
        roleId: this.role,
        hospitalId: this.hospitalId,
        userTimezone: timezone !== null ? timezone?.zoneName : '',
      };
      if (this.role === 4) {
        await this.$store.dispatch("users/createUser", body).then(async (res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            this.loading = false;
            this.$emit("false");
            let metadatabody = {
              userId: res.data.id,
              metadataInfo: '',
              licenceId: this.licenceNumber,
              hospitalId: this.hospitalId
            }
            await this.editNurseMetaData(metadatabody)
          } else {
            this.loading = false;
            this.snackStatus = true;
            this.snackColor = "deep-orange darken-4";
            this.title = res.msg;
          }
        });
      } else if (this.role === 3) {
        let metadatabody = {
          userId: null,
          metadataInfo: '',
          licenceId: this.licenceNumber,
        }
        await this.$store.dispatch("users/createUser", body).then(async (res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            metadatabody.userId = res.data.id
            let specializationbody = {
              doctorUserId: res.data.id,
              spetializationTypeId: null,
              hospitalId: this.hospitalId
            }
            await this.updateDoctorMetaData(metadatabody)
            this.loading = false
            this.$emit("false")
            if (this.selected.length > 0) {
              this.selected.forEach(async(s) => {
                specializationbody.spetializationTypeId = s
                await this.assignDoctorToSpetialization(specializationbody)
              })
            }
          } else {
            this.loading = false;
            this.snackStatus = true;
            this.snackColor = "deep-orange darken-4";
            this.title = res.msg;
          }
        })
      }
    },
    async getHospitals() {
      await this.$store.dispatch("hospitals/getHospitals").then(() => {
        if (this.hospitalsFromRepo !== null) {
          this.hospitals = this.hospitalsFromRepo;
        } else this.hospitals = [];
      });
    },
    async getRoles() {
      await this.$store.dispatch("roles/getRoles").then(() => {
        this.roles = this.rolesFromRepo.filter(
          (role) => role.roleName === "DOCTOR" || role.roleName === "NURSE"
        );
      });
    },
  },
};
</script>

<style>
  ::-webkit-scrollbar {
    width: 0px !important;
  }
</style>