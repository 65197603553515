<template>
  <div>
    <v-row class="mx-2">
      <v-col cols="12">
        <v-card flat style="border-radius: 12px">
          <v-card-title>
            <v-row>
              <v-col class="d-flex align-center justify-start" cols="8">
                <h5 class="d-flex align-left">{{ $t("staff") }}</h5>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search-staff')"
                  single-line
                  hide-details
                  filled
                  dense
                  background-color="#E8E8E8"
                  rounded
                  class="table-search-button"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            v-if="users"
            :loading="loading"
            :headers="headers"
            :search="search"
            :items="users"
            :items-per-page="users.length"
            sort-by="id"
            group-by="roleId"
            item-key="id"
            fixed-header
            style="cursor: pointer"
            :height="testHeight"
            :single-expand="true"
            :expanded.sync="expanded"
          >
            <template
              v-slot:[`group.header`]="{
                group,
                headers,
                toggle,
                isOpen,
                items,
              }"
            >
              <td
                style="
                  vertical-align: left !important;
                  text-align: left !important;
                "
                :colspan="headers.length"
                @click="toggle"
              >
                <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
                <span style="font-weight: bold">
                  {{ group === 3 ? "Doctors" : "Nurses" }}</span
                >
                <v-chip pill x-small color="white" class="ma-2 text--black">
                  {{ items.length }}
                </v-chip>
              </td>
              <v-spacer></v-spacer>
            </template>
            <template
              v-if="roleName === 'HOSPITAL_ADMIN'"
              v-slot:[`footer.prepend`]="{}"
            >
              <v-btn
                class="table-create-button"
                label="AddDialog"
                @click="dialog = true"
              >
                <v-icon class="icon">mdi-plus</v-icon>
                <span>{{ $t("addNewHospitalStaffTooltip") }}</span>
              </v-btn>
            </template>
            <template v-slot:item="{ item }">
              <tr @click="expand(item)">
                <td class="table-cols">
                  <img v-if="item.roleId === 4" :src="nurse_icon(item.status)" color="primary" height="24px">
                  <img :src="doctor_icon(item.status)" v-if="item.roleId === 3" color="primary" height="24px">
                </td>
                <td class="table-cols">
                  {{ item.fullName }}
                </td>
                <td class="table-cols">
                  {{ item.address }}
                </td>
                <td class="table-cols">
                  {{ item.email }}
                </td>
                <td class="table-cols">
                  {{ item.phone }}
                </td>
                <td class="table-cols">
                  <span v-if="item.roleId === 3 && item.doctorMetadataReadDto">
                    {{ item.doctorMetadataReadDto.licenceId }}
                  </span>
                  <span v-if="item.roleId === 4 && item.nurseMetadata">
                    {{ item.nurseMetadata.licenceId }}
                  </span>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <DetailsCard v-if="expanded" :type="'mstaff'" :item="item"></DetailsCard>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <AddStaffDialog v-if="dialog" :dialog="dialog" @false="closeDialog()" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import AddStaffDialog from "@/views/dialogs/MedicalStaffDialog.vue";
import DetailsCard from '@/views/Client/components/DetailsCard.vue'
import { doctor_black, doctor_secondary, nurse_black, nurse_secondary } from '@/assets'

export default {
  components: {
    AddStaffDialog,
    DetailsCard
  },
  computed: {
    ...mapState({
      relatedUsers: (state) => state.hospitals.users,
      medicalStaff: (state) => state.hospitals.medicalStaff,
    }),
    ...mapGetters({
      hospitalId: "authentication/getHospitalId",
      pageHeight: "pageHeight",
      roleName: "authentication/getRole",
    }),
    testHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return window.innerHeight - 320
        case 'sm': return window.innerHeight - 300
        case 'md': return window.innerHeight - 300
        case 'lg': return window.innerHeight - 280
        case 'xl': return window.innerHeight - 280
        default: return 0
      }
    },
    users() {
      return this.medicalStaff?.filter((ru) => {
        ru.statusDescription = ru.status === 1 ? "Active" : "Inactive";
        ru.fullName = ru.firstName.concat(" ", ru.lastName);
        switch (ru.roleId) {
          case 3:
            ru.role = "Doctor";
            break;
          case 4:
            ru.role = "Nurse";
            break;
          default:
            break;
        }
        return ru.roleId === 3 || ru.roleId === 4;
      });
    },
  },
  data() {
    return {
      expanded: [],
      loading: false,
      dialog: false,
      search: null,
      headers: [
        { text: this.$t("header-status"), value: "statusDescription" },
        { text: this.$t("header-full-name"), value: "fullName" },
        { text: this.$t("header-address"), value: "address" },
        { text: this.$t("header-email"), value: "email" },
        { text: this.$t("header-phone"), value: "phone" },
        {
          text: this.$t("header-medical-licence-id"),
          value: "licence",
        },
      ],
      doctor_black: doctor_black,
      doctor_secondary: doctor_secondary,
      nurse_black: nurse_black,
      nurse_secondary: nurse_secondary,
    };
  },
  async mounted() {
    await this.getStaff();
  },
  methods: {
    async expand (item) {
			if (item === this.expanded[0]) this.expanded = [];
			else this.expanded = [item];
		},
    async getStaff() {
      this.loading = true;
      await this.$store
        .dispatch("hospitals/getRelatedMedicalStaff", this.hospitalId)
        .then(() => {
          this.loading = false;
        });
    },
    closeDialog() {
      this.dialog = false;
      this.getStaff();
    },
    doctor_icon (item) {
      switch (item) {
        case 1:
          return this.doctor_black
        default:
          return this.doctor_secondary
      }
    },
    nurse_icon (item) {
      switch (item) {
        case 1:
          return this.nurse_black
        default:
          return this.nurse_secondary
      }
    },
  },
};
</script>