var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticStyle:{"border-radius":"12px"},attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-start",attrs:{"cols":"8"}},[_c('h5',{staticClass:"d-flex align-left"},[_vm._v(_vm._s(_vm.$t("staff")))])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"table-search-button",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search-staff'),"single-line":"","hide-details":"","filled":"","dense":"","background-color":"#E8E8E8","rounded":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),(_vm.users)?_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"loading":_vm.loading,"headers":_vm.headers,"search":_vm.search,"items":_vm.users,"items-per-page":_vm.users.length,"sort-by":"id","group-by":"roleId","item-key":"id","fixed-header":"","height":_vm.testHeight,"single-expand":true,"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
            var group = ref.group;
            var headers = ref.headers;
            var toggle = ref.toggle;
            var isOpen = ref.isOpen;
            var items = ref.items;
return [_c('td',{staticStyle:{"vertical-align":"left !important","text-align":"left !important"},attrs:{"colspan":headers.length},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(group === 3 ? "Doctors" : "Nurses"))]),_c('v-chip',{staticClass:"ma-2 text--black",attrs:{"pill":"","x-small":"","color":"white"}},[_vm._v(" "+_vm._s(items.length)+" ")])],1),_c('v-spacer')]}},(_vm.roleName === 'HOSPITAL_ADMIN')?{key:"footer.prepend",fn:function(ref){return [_c('v-btn',{staticClass:"table-create-button",attrs:{"label":"AddDialog"},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t("addNewHospitalStaffTooltip")))])],1)]}}:null,{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.expand(item)}}},[_c('td',{staticClass:"table-cols"},[(item.roleId === 4)?_c('img',{attrs:{"src":_vm.nurse_icon(item.status),"color":"primary","height":"24px"}}):_vm._e(),(item.roleId === 3)?_c('img',{attrs:{"src":_vm.doctor_icon(item.status),"color":"primary","height":"24px"}}):_vm._e()]),_c('td',{staticClass:"table-cols"},[_vm._v(" "+_vm._s(item.fullName)+" ")]),_c('td',{staticClass:"table-cols"},[_vm._v(" "+_vm._s(item.address)+" ")]),_c('td',{staticClass:"table-cols"},[_vm._v(" "+_vm._s(item.email)+" ")]),_c('td',{staticClass:"table-cols"},[_vm._v(" "+_vm._s(item.phone)+" ")]),_c('td',{staticClass:"table-cols"},[(item.roleId === 3 && item.doctorMetadataReadDto)?_c('span',[_vm._v(" "+_vm._s(item.doctorMetadataReadDto.licenceId)+" ")]):_vm._e(),(item.roleId === 4 && item.nurseMetadata)?_c('span',[_vm._v(" "+_vm._s(item.nurseMetadata.licenceId)+" ")]):_vm._e()])])]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(_vm.expanded)?_c('DetailsCard',{attrs:{"type":'mstaff',"item":item}}):_vm._e()],1)]}}],null,true)}):_vm._e()],1),(_vm.dialog)?_c('AddStaffDialog',{attrs:{"dialog":_vm.dialog},on:{"false":function($event){return _vm.closeDialog()}}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }